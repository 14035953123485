@import './fonts/baloo2/baloo2.css';
@import './fonts/liberationMono/liberationMono.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
}
