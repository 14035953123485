@font-face {
  font-family: baloo2;
  src: url("400-normal.90bffe61.ttf");
  font-weight: 400;
}

@font-face {
  font-family: baloo2;
  src: url("500-medium.04b0aae6.ttf");
  font-weight: 500;
}

@font-face {
  font-family: baloo2;
  src: url("600-semibold.d3f2d94f.ttf");
  font-weight: 600;
}

@font-face {
  font-family: baloo2;
  src: url("700-bold.02fafc66.ttf");
  font-weight: 700;
}

@font-face {
  font-family: baloo2;
  src: url("800-extrabold.07ed949c.ttf");
  font-weight: 800;
}

@font-face {
  font-family: liberationMono;
  src: url("400-normal.3db63a5d.ttf");
  font-weight: 400;
}

@font-face {
  font-family: liberationMono;
  src: url("700-bold.94877141.ttf");
  font-weight: 700;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-bottom-1 {
  bottom: -.25rem;
}

.-bottom-4 {
  bottom: -1rem;
}

.-right-2 {
  right: -.5rem;
}

.-right-px {
  right: -1px;
}

.-top-4 {
  top: -1rem;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-\[-7px\] {
  left: -7px;
}

.right-0 {
  right: 0;
}

.right-\[calc\(1px_-_12px\)\] {
  right: -11px;
}

.top-0 {
  top: 0;
}

.top-\[-7px\] {
  top: -7px;
}

.top-\[calc\(100\%_-_2px\)\] {
  top: calc(100% - 2px);
}

.top-full {
  top: 100%;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-start-1 {
  grid-row-start: 1;
}

.-m-1 {
  margin: -.25rem;
}

.m-0 {
  margin: 0;
}

.m-0\.5 {
  margin: .125rem;
}

.m-\[-6\%\] {
  margin: -6%;
}

.-mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.-mx-0\.5 {
  margin-left: -.125rem;
  margin-right: -.125rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-mx-2\.5 {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-0\.5 {
  margin-left: .125rem;
  margin-right: .125rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.-mb-1 {
  margin-bottom: -.25rem;
}

.-mb-1\.5 {
  margin-bottom: -.375rem;
}

.-ml-0 {
  margin-left: 0;
}

.-ml-0\.5 {
  margin-left: -.125rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-ml-2\.5 {
  margin-left: -.625rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-mr-2\.5 {
  margin-right: -.625rem;
}

.-mt-0 {
  margin-top: 0;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-1\.5 {
  margin-top: -.375rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-2\.5 {
  margin-top: -.625rem;
}

.-mt-px {
  margin-top: -1px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-\[-3\%\] {
  margin-left: -3%;
}

.ml-\[-6\%\] {
  margin-left: -6%;
}

.ml-\[31\.5\%\] {
  margin-left: 31.5%;
}

.ml-\[4\.5\%\] {
  margin-left: 4.5%;
}

.ml-\[5\%\] {
  margin-left: 5%;
}

.ml-\[6\.5\%\] {
  margin-left: 6.5%;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-\[-100\%\] {
  margin-right: -100%;
}

.mr-\[-3px\] {
  margin-right: -3px;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-\[14\.5\%\] {
  margin-top: 14.5%;
}

.mt-\[15px\] {
  margin-top: 15px;
}

.mt-\[19\%\] {
  margin-top: 19%;
}

.mt-\[22\%\] {
  margin-top: 22%;
}

.mt-\[3px\] {
  margin-top: 3px;
}

.mt-\[5px\] {
  margin-top: 5px;
}

.mt-\[9\%\] {
  margin-top: 9%;
}

.mt-px {
  margin-top: 1px;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.size-0 {
  width: 0;
  height: 0;
}

.size-0\.5 {
  width: .125rem;
  height: .125rem;
}

.size-1 {
  width: .25rem;
  height: .25rem;
}

.size-1\.5 {
  width: .375rem;
  height: .375rem;
}

.size-10 {
  width: 2.5rem;
  height: 2.5rem;
}

.size-12 {
  width: 3rem;
  height: 3rem;
}

.size-16 {
  width: 4rem;
  height: 4rem;
}

.size-2 {
  width: .5rem;
  height: .5rem;
}

.size-2\.5 {
  width: .625rem;
  height: .625rem;
}

.size-24 {
  width: 6rem;
  height: 6rem;
}

.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.size-6 {
  width: 1.5rem;
  height: 1.5rem;
}

.size-7 {
  width: 1.75rem;
  height: 1.75rem;
}

.size-8 {
  width: 2rem;
  height: 2rem;
}

.size-\[18px\] {
  width: 18px;
  height: 18px;
}

.size-\[200\%\] {
  width: 200%;
  height: 200%;
}

.size-\[78px\] {
  width: 78px;
  height: 78px;
}

.size-\[calc\(100\%_-_4px\)\] {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
}

.size-full {
  width: 100%;
  height: 100%;
}

.\!h-6 {
  height: 1.5rem !important;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-20 {
  height: 5rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-64 {
  height: 16rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[1em\] {
  height: 1em;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[54px\] {
  height: 54px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-64 {
  max-height: 16rem;
}

.max-h-\[calc\(100vh_-_5rem\)\] {
  max-height: calc(100vh - 5rem);
}

.min-h-full {
  min-height: 100%;
}

.\!w-20 {
  width: 5rem !important;
}

.\!w-3 {
  width: .75rem !important;
}

.\!w-\[calc\(100\%_\+_8px\)\] {
  width: calc(100% + 8px) !important;
}

.w-0 {
  width: 0;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-20 {
  width: 5rem;
}

.w-3 {
  width: .75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[106\%\] {
  width: 106%;
}

.w-\[112\%\] {
  width: 112%;
}

.w-\[130px\] {
  width: 130px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[37\%\] {
  width: 37%;
}

.w-\[384px\] {
  width: 384px;
}

.w-\[500px\] {
  width: 500px;
}

.w-\[54px\] {
  width: 54px;
}

.w-\[86\%\] {
  width: 86%;
}

.w-\[90\%\] {
  width: 90%;
}

.w-\[92\%\] {
  width: 92%;
}

.w-\[calc\(100\%_-_1rem\)\] {
  width: calc(100% - 1rem);
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-\[320px\] {
  min-width: 320px;
}

.min-w-\[32px\] {
  min-width: 32px;
}

.min-w-\[336px\] {
  min-width: 336px;
}

.min-w-full {
  min-width: 100%;
}

.max-w-64 {
  max-width: 16rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-\[100vw\] {
  max-width: 100vw;
}

.max-w-\[104rem\] {
  max-width: 104rem;
}

.max-w-\[164px\] {
  max-width: 164px;
}

.max-w-none {
  max-width: none;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.basis-0 {
  flex-basis: 0;
}

.origin-bottom-left {
  transform-origin: 0 100%;
}

.origin-center {
  transform-origin: center;
}

.origin-left {
  transform-origin: 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-12 {
  --tw-rotate: 12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[-128\.5deg\] {
  --tw-rotate: -128.5deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-x-100 {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-my-spin {
  animation: 40s linear infinite spin;
}

.animate-my-spin-reverse {
  animation: 10s linear infinite reverse spin;
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-pointer {
  cursor: pointer;
}

.touch-pinch-zoom {
  --tw-pinch-zoom: pinch-zoom;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-\[1fr_auto\] {
  grid-template-columns: 1fr auto;
}

.grid-cols-\[auto_1fr\] {
  grid-template-columns: auto 1fr;
}

.grid-cols-\[auto_auto\] {
  grid-template-columns: auto auto;
}

.grid-cols-\[auto_auto_1fr\] {
  grid-template-columns: auto auto 1fr;
}

.grid-cols-\[auto_auto_auto\] {
  grid-template-columns: auto auto auto;
}

.grid-cols-\[auto_repeat\(3\,1fr\)\] {
  grid-template-columns: auto repeat(3, 1fr);
}

.grid-cols-\[repeat\(7\,54px\)_34px\] {
  grid-template-columns: repeat(7, 54px) 34px;
}

.grid-cols-\[repeat\(auto-fit\,10px\)\] {
  grid-template-columns: repeat(auto-fit, 10px);
}

.grid-cols-\[repeat\(auto-fit\,1px\)\] {
  grid-template-columns: repeat(auto-fit, 1px);
}

.grid-cols-\[repeat\(auto-fit\,3rem\)\] {
  grid-template-columns: repeat(auto-fit, 3rem);
}

.grid-cols-\[repeat\(auto-fit\,4rem\)\] {
  grid-template-columns: repeat(auto-fit, 4rem);
}

.grid-rows-\[1fr_auto\] {
  grid-template-rows: 1fr auto;
}

.grid-rows-\[1fr_auto_auto_1fr\] {
  grid-template-rows: 1fr auto auto 1fr;
}

.grid-rows-\[auto_1fr\] {
  grid-template-rows: auto 1fr;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-center {
  justify-items: center;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-3\.5 {
  gap: .875rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[3px\] {
  gap: 3px;
}

.gap-px {
  gap: 1px;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-1\.5 {
  column-gap: .375rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-\[15px\] {
  column-gap: 15px;
}

.gap-y-0 {
  row-gap: 0;
}

.gap-y-0\.5 {
  row-gap: .125rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-3 {
  row-gap: .75rem;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.\!whitespace-normal {
  white-space: normal !important;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.rounded {
  border-radius: .25rem;
}

.rounded-1\/2 {
  border-radius: 50%;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-b-\[5px\] {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-r-xl {
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-\[5px\] {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-bl-xl {
  border-bottom-left-radius: .75rem;
}

.rounded-br {
  border-bottom-right-radius: .25rem;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-br-xl {
  border-bottom-right-radius: .75rem;
}

.rounded-tl {
  border-top-left-radius: .25rem;
}

.rounded-tl-\[5px\] {
  border-top-left-radius: 5px;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.rounded-tr-xl {
  border-top-right-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-\[3px\] {
  border-width: 3px;
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-r {
  border-right-width: 1px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-dashed {
  border-style: dashed;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-goldenrod {
  --tw-border-opacity: 1;
  border-color: rgb(218 165 32 / var(--tw-border-opacity));
}

.border-goldenrod-bis {
  --tw-border-opacity: 1;
  border-color: rgb(181 135 3 / var(--tw-border-opacity));
}

.border-green-toaster-bis {
  --tw-border-opacity: 1;
  border-color: rgb(0 100 0 / var(--tw-border-opacity));
}

.border-grey-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-grey-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-grey-disabled {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 91 / var(--tw-border-opacity));
}

.border-red-ban {
  --tw-border-opacity: 1;
  border-color: rgb(153 27 27 / var(--tw-border-opacity));
}

.border-red-toaster-bis {
  --tw-border-opacity: 1;
  border-color: rgb(139 0 0 / var(--tw-border-opacity));
}

.border-tooltip {
  --tw-border-opacity: 1;
  border-color: rgb(114 90 52 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-y-transparent {
  border-top-color: #0000;
  border-bottom-color: #0000;
}

.border-b-goldenrod-bis {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(181 135 3 / var(--tw-border-opacity));
}

.border-b-transparent {
  border-bottom-color: #0000;
}

.border-t-transparent {
  border-top-color: #0000;
}

.bg-\[\#149c3a\] {
  --tw-bg-opacity: 1;
  background-color: rgb(20 156 58 / var(--tw-bg-opacity));
}

.bg-\[\#25acd6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(37 172 214 / var(--tw-bg-opacity));
}

.bg-\[\#cd4545\] {
  --tw-bg-opacity: 1;
  background-color: rgb(205 69 69 / var(--tw-bg-opacity));
}

.bg-\[\#cd8837\] {
  --tw-bg-opacity: 1;
  background-color: rgb(205 136 55 / var(--tw-bg-opacity));
}

.bg-aram-stats {
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/50 {
  background-color: #00000080;
}

.bg-current {
  background-color: currentColor;
}

.bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

.bg-discord-blurple {
  --tw-bg-opacity: 1;
  background-color: rgb(88 101 242 / var(--tw-bg-opacity));
}

.bg-discord-darkgreen {
  --tw-bg-opacity: 1;
  background-color: rgb(36 128 69 / var(--tw-bg-opacity));
}

.bg-discord-darkgrey {
  --tw-bg-opacity: 1;
  background-color: rgb(43 45 49 / var(--tw-bg-opacity));
}

.bg-goldenrod {
  --tw-bg-opacity: 1;
  background-color: rgb(218 165 32 / var(--tw-bg-opacity));
}

.bg-goldenrod-bis {
  --tw-bg-opacity: 1;
  background-color: rgb(181 135 3 / var(--tw-bg-opacity));
}

.bg-green-toaster {
  --tw-bg-opacity: 1;
  background-color: rgb(34 139 34 / var(--tw-bg-opacity));
}

.bg-histogram-grey\/50 {
  background-color: #4b556380;
}

.bg-red-toaster {
  --tw-bg-opacity: 1;
  background-color: rgb(181 20 20 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-zinc-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

.bg-zinc-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 27 / var(--tw-bg-opacity));
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-\[\#0369a1\] {
  --tw-gradient-from: #0369a1 var(--tw-gradient-from-position);
  --tw-gradient-to: #0369a100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#329055\] {
  --tw-gradient-from: #329055 var(--tw-gradient-from-position);
  --tw-gradient-to: #32905500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#71717a\] {
  --tw-gradient-from: #71717a var(--tw-gradient-from-position);
  --tw-gradient-to: #71717a00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#8d12c9\] {
  --tw-gradient-from: #8d12c9 var(--tw-gradient-from-position);
  --tw-gradient-to: #8d12c900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#94a3b8\] {
  --tw-gradient-from: #94a3b8 var(--tw-gradient-from-position);
  --tw-gradient-to: #94a3b800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#b71d3c\] {
  --tw-gradient-from: #b71d3c var(--tw-gradient-from-position);
  --tw-gradient-to: #b71d3c00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#d2651b\] {
  --tw-gradient-from: #d2651b var(--tw-gradient-from-position);
  --tw-gradient-to: #d2651b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-dark {
  --tw-gradient-from: #020617 var(--tw-gradient-from-position);
  --tw-gradient-to: #02061700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-zinc-950 {
  --tw-gradient-from: #09090b var(--tw-gradient-from-position);
  --tw-gradient-to: #09090b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#266846\] {
  --tw-gradient-to: #266846 var(--tw-gradient-to-position);
}

.to-\[\#324bd0\] {
  --tw-gradient-to: #324bd0 var(--tw-gradient-to-position);
}

.to-\[\#475569\] {
  --tw-gradient-to: #475569 var(--tw-gradient-to-position);
}

.to-\[\#52525b\] {
  --tw-gradient-to: #52525b var(--tw-gradient-to-position);
}

.to-\[\#560292\] {
  --tw-gradient-to: #560292 var(--tw-gradient-to-position);
}

.to-\[\#640411\] {
  --tw-gradient-to: #640411 var(--tw-gradient-to-position);
}

.to-\[\#8e3d25\] {
  --tw-gradient-to: #8e3d25 var(--tw-gradient-to-position);
}

.to-slate {
  --tw-gradient-to: #0f172a var(--tw-gradient-to-position);
}

.to-zinc-900 {
  --tw-gradient-to: #18181b var(--tw-gradient-to-position);
}

.bg-contain {
  background-size: contain;
}

.\!p-0 {
  padding: 0 !important;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[3px\] {
  padding: 3px;
}

.p-px {
  padding: 1px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-\[5px\] {
  padding-left: 5px;
  padding-right: 5px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[3px\] {
  padding-top: 3px;
  padding-bottom: 3px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0\.5 {
  padding-bottom: .125rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-\[3px\] {
  padding-bottom: 3px;
}

.pl-0 {
  padding-left: 0;
}

.pl-0\.5 {
  padding-left: .125rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-2\.5 {
  padding-left: .625rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-px {
  padding-left: 1px;
}

.pr-0 {
  padding-right: 0;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-2\.5 {
  padding-right: .625rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-\[5px\] {
  padding-right: 5px;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-\[39px\] {
  padding-top: 39px;
}

.pt-px {
  padding-top: 1px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-baloo {
  font-family: baloo2;
}

.font-lib-mono {
  font-family: liberationMono;
}

.\!text-xs {
  font-size: .75rem !important;
  line-height: 1rem !important;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-2xs {
  font-size: .625rem;
  line-height: .75rem;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.leading-2\.5 {
  line-height: .625rem;
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-\[9px\] {
  line-height: 9px;
}

.text-\[\#0369a1\] {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity));
}

.text-\[\#22c55e\] {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-\[\#329055\] {
  --tw-text-opacity: 1;
  color: rgb(50 144 85 / var(--tw-text-opacity));
}

.text-\[\#3b82f6\] {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-\[\#71717a\] {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}

.text-\[\#8d12c9\] {
  --tw-text-opacity: 1;
  color: rgb(141 18 201 / var(--tw-text-opacity));
}

.text-\[\#94a3b8\] {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-\[\#b71d3c\] {
  --tw-text-opacity: 1;
  color: rgb(183 29 60 / var(--tw-text-opacity));
}

.text-\[\#cbd5e1\] {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-\[\#d2651b\] {
  --tw-text-opacity: 1;
  color: rgb(210 101 27 / var(--tw-text-opacity));
}

.text-\[\#d4d4d4\] {
  --tw-text-opacity: 1;
  color: rgb(212 212 212 / var(--tw-text-opacity));
}

.text-\[\#e752fb\] {
  --tw-text-opacity: 1;
  color: rgb(231 82 251 / var(--tw-text-opacity));
}

.text-\[\#f5378e\] {
  --tw-text-opacity: 1;
  color: rgb(245 55 142 / var(--tw-text-opacity));
}

.text-\[\#f6a738\] {
  --tw-text-opacity: 1;
  color: rgb(246 167 56 / var(--tw-text-opacity));
}

.text-\[beige\] {
  --tw-text-opacity: 1;
  color: rgb(245 245 220 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity));
}

.text-goldenrod {
  --tw-text-opacity: 1;
  color: rgb(218 165 32 / var(--tw-text-opacity));
}

.text-goldenrod-bis {
  --tw-text-opacity: 1;
  color: rgb(181 135 3 / var(--tw-text-opacity));
}

.text-green {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-grey-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-grey-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-tooltip {
  --tw-text-opacity: 1;
  color: rgb(114 90 52 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-wheat {
  --tw-text-opacity: 1;
  color: rgb(245 222 179 / var(--tw-text-opacity));
}

.text-wheat-bis {
  --tw-text-opacity: 1;
  color: rgb(200 171 109 / var(--tw-text-opacity));
}

.text-wheat\/0 {
  color: #f5deb300;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-zinc-400 {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.caret-wheat {
  caret-color: wheat;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.opacity-70 {
  opacity: .7;
}

.shadow-even {
  --tw-shadow: 0 0 8px 0 var(--tw-shadow-color);
  --tw-shadow-colored: 0 0 8px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-black {
  --tw-shadow-color: black;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline {
  outline-style: solid;
}

.outline-1 {
  outline-width: 1px;
}

.outline-white {
  outline-color: #fff;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.brightness-75 {
  --tw-brightness: brightness(.75);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-\[0_0_3px_black\] {
  --tw-drop-shadow: drop-shadow(0 0 3px black);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-\[\.7\] {
  --tw-saturate: saturate(.7);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.sepia {
  --tw-sepia: sepia(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.area-1 {
  grid-area: 1 / 1;
}

.text-shadow {
  text-shadow: 0 0 5px var(--tw-shadow-color);
}

html {
  overflow: hidden;
}

.first\:pl-2:first-child {
  padding-left: .5rem;
}

.last\:mb-1:last-child {
  margin-bottom: .25rem;
}

.last\:pr-2:last-child {
  padding-right: .5rem;
}

.odd\:bg-zinc-900:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 27 / var(--tw-bg-opacity));
}

.even\:bg-zinc-700:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.hover\:bg-goldenrod\/75:hover {
  background-color: #daa520bf;
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.enabled\:hover\:bg-goldenrod\/75:hover:enabled {
  background-color: #daa520bf;
}

.enabled\:hover\:text-red:hover:enabled {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.enabled\:hover\:text-wheat:hover:enabled {
  --tw-text-opacity: 1;
  color: rgb(245 222 179 / var(--tw-text-opacity));
}

.enabled\:active\:border-dashed:active:enabled {
  border-style: dashed;
}

.disabled\:border-grey-400:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.disabled\:bg-grey-disabled:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}

.disabled\:text-grey-400:disabled {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.disabled\:opacity-25:disabled {
  opacity: .25;
}

.disabled\:opacity-30:disabled {
  opacity: .3;
}

.group\/checkbox:first-child .group-first\/checkbox\:rounded-l-md, .group:first-child .group-first\:rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.group\/checkbox:first-child .group-first\/checkbox\:rounded-tl-md {
  border-top-left-radius: .375rem;
}

.group:first-child .group-first\:border-l-0 {
  border-left-width: 0;
}

.group\/checkbox:last-child .group-last\/checkbox\:rounded-r-md, .group:last-child .group-last\:rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.group\/checkbox:last-child .group-last\/checkbox\:rounded-tr-md {
  border-top-right-radius: .375rem;
}

.group:hover .group-hover\:flex {
  display: flex;
}

.group:hover .group-hover\:hidden {
  display: none;
}

.group:hover .group-hover\:w-4 {
  width: 1rem;
}

.group:hover .group-hover\:border-b-current {
  border-bottom-color: currentColor;
}

.group:hover .group-hover\:border-b-goldenrod {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(218 165 32 / var(--tw-border-opacity));
}

.group:hover .group-hover\:pl-1 {
  padding-left: .25rem;
}

.group:hover .group-hover\:pt-0 {
  padding-top: 0;
}

.group:hover .group-hover\:pt-0\.5 {
  padding-top: .125rem;
}

@keyframes dice {
  0% {
    transform: rotate(0);
  }

  33% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(720deg);
  }
}

.group:enabled:hover .group-enabled\:group-hover\:animate-dice {
  animation: 2s ease-in-out infinite dice;
}

.group[data-popper-placement^="top"] .group-data-popper-top\:-bottom-1 {
  bottom: -.25rem;
}

.group[data-popper-placement^="top"] .group-data-popper-top\:-bottom-1\.5 {
  bottom: -.375rem;
}

.group[data-popper-placement^="top"] .group-data-popper-top\:rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-popper-placement^="bottom"] .group-data-popper-bottom\:-top-1 {
  top: -.25rem;
}

.group[data-popper-placement^="bottom"] .group-data-popper-bottom\:-top-1\.5 {
  top: -.375rem;
}

.group[data-popper-placement^="bottom"] .group-data-popper-bottom\:rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-popper-placement^="left"] .group-data-popper-left\:-right-2 {
  right: -.5rem;
}

.group[data-popper-placement^="left"] .group-data-popper-left\:rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group[data-popper-placement^="right"] .group-data-popper-right\:-left-2 {
  left: -.5rem;
}

.group[data-popper-placement^="right"] .group-data-popper-right\:-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\>\*\]\:text-wheat > * {
  --tw-text-opacity: 1;
  color: rgb(245 222 179 / var(--tw-text-opacity));
}
/*# sourceMappingURL=index.9a525bd7.css.map */
